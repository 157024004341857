$font_format: 'woff2';

@mixin font-face(
  $font-family,
  $font-weight,
  $font-style,
  $font-source-type,
  $font-source,
  $base-url,
  $additional-properties: ()
) {
  @font-face {
    font-family: $font-family;
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: swap;

    @if $font-source-type == 'local' {
      src: local($font-source);
    } @else {
      src: url('#{$base_url}/fonts/#{$font-source}') format($font-format);
    }

    @each $property, $value in $additional-properties {
      #{$property}: $value;
    }
  }
}
