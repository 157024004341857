@import '../../../utils/mixin_utils.scss';

:root {
  --tenant-lang-specific-font-family--regular: 'GT-Walsheim-Pro-Regular', 'Noto-Sans-Thai-Regular',
    'GT-Walsheim-Pro-Regular-Fallback-Arial';
  --tenant-lang-specific-font-family--medium: 'GT-Walsheim-Pro-Medium', 'Noto-Sans-Thai-Medium',
    'GT-Walsheim-Pro-Medium-Fallback-Arial';
  --tenant-lang-specific-font-family--bold: 'GT-Walsheim-Pro-Bold', 'Noto-Sans-Thai-Bold',
    'GT-Walsheim-Pro-Bold-Fallback-Arial';

    --font-size-h1: 3.063rem; /* 49px */
    --font-size-h2: 2.625rem; /* 42px */
    --font-size-h3: 2rem; /* 32px */
    --font-size-h4: 1.5rem; /* 24px */
    --font-size-subtitle-1: 1.313rem; /* 21px */
    --font-size-subtitle-2: 1.125rem; /* 18px */
    --font-size-body-1: 1rem; /* 16px */
    --font-size-body-2: 0.875rem; /* 14px */
    --font-size-overline: 0.875rem; /* 14px */
    --font-size-caption: 0.875rem; /* 14px */
    --font-size-headline-bold: 1.313rem; /* 21px */
    --font-size-button-body-1: 1rem; /* 16px */
    --font-size-button-body-2: 0.875rem; /* 14px */
    --font-size-product-cards: 1.313rem; /* 21px */
    --font-size-modal-headline: 1.313rem; /* 21px */
    --font-size-image-upload-icon: 2.125rem; /* 34px */
    --font-size-drawer-small-icon: 1.25rem; /* 20px */
    --font-size-drawer-large-icon: 1.75rem; /* 28px */
  
    --line-height-h1: 3.5rem; /* 56px */
    --line-height-h2: 3rem; /* 48px */
    --line-height-h3: 2.25rem; /* 36px */
    --line-height-h4: 1.75rem; /* 28px */
    --line-height-subtitle-1: 1.5rem; /* 24px */
    --line-height-subtitle-2: 1.313rem; /* 21px */
    --line-height-body-1: 1.5rem; /* 24px */
    --line-height-body-2: 1.125rem; /* 18px */
    --line-height-overline: 1.125rem; /* 18px */
    --line-height-caption: 1rem; /* 16px */
    --line-height-headline-bold: 1.75rem; /* 28px */
    --line-height-button-body-1: 1.5rem; /* 24px */
    --line-height-button-body-2: 1rem; /* 16px */
    --line-height-product-cards: 1.313rem; /* 21px */
    --line-height-modal-headline: 1.75rem; /* 28px */
    --line-height-drawer-large-icon: 1.25rem; /* 20px */
    --line-height-nav-large-icon: 1.75rem; /* 28px */
}

// body.en-us {
//     --tenant-lang-specific-font-family--regular: 'GT-Walsheim-Pro-Regular', 'Noto-Sans-Thai-Regular',
//       'GT-Walsheim-Pro-Regular-Fallback-Arial';
//     --tenant-lang-specific-font-family--medium: 'GT-Walsheim-Pro-Medium', 'Noto-Sans-Thai-Medium',
//       'GT-Walsheim-Pro-Medium-Fallback-Arial';
//     --tenant-lang-specific-font-family--bold: 'GT-Walsheim-Pro-Bold', 'Noto-Sans-Thai-Bold',
//       'GT-Walsheim-Pro-Bold-Fallback-Arial';

// }

// body.th-th {

//     --tenant-lang-specific-font-family--regular: 'GT-Walsheim-Pro-Regular', 'Noto-Sans-Thai-Regular',
//       'GT-Walsheim-Pro-Regular-Fallback-Arial';
//     --tenant-lang-specific-font-family--medium: 'GT-Walsheim-Pro-Medium', 'Noto-Sans-Thai-Medium',
//       'GT-Walsheim-Pro-Medium-Fallback-Arial';
//     --tenant-lang-specific-font-family--bold: 'GT-Walsheim-Pro-Bold', 'Noto-Sans-Thai-Bold',
//       'GT-Walsheim-Pro-Bold-Fallback-Arial';
// }

@mixin font-imports($base_url: 'https://webfont-dev.amcom-corp-preprod.amway.net') {
  @include font-face('GT-Walsheim-Pro-Bold', 700, normal, 'url', 'GT-Walsheim-Pro-Bold.woff2', $base_url);
  @include font-face('GT-Walsheim-Pro-Medium', 400, normal, 'url', 'GT-Walsheim-Pro-Medium.woff2', $base_url);
  @include font-face('GT-Walsheim-Pro-Regular', 400, normal, 'url', 'GT-Walsheim-Pro-Regular.woff2', $base_url);
  @include font-face('Noto-Sans-Thai-Bold', 700, normal, 'url', 'NotoSansThai-Bold.ttf', $base_url);
  @include font-face('Noto-Sans-Thai-Medium', 400, normal, 'url', 'NotoSansThai-Medium.ttf', $base_url);
  @include font-face('Noto-Sans-Thai-Regular', 400, normal, 'url', 'NotoSansThai-Regular.ttf', $base_url);
  @include font-face(
    'GT-Walsheim-Pro-Bold-Fallback-Arial',
    700,
    normal,
    'local',
    'Arial',
    $base_url,
    (
      ascent-override: 82.44%,
      descent-override: 22.44%,
      line-gap-override: 0%,
      size-adjust: 109.17%,
    )
  );
  @include font-face(
    'GT-Walsheim-Pro-Medium-Fallback-Arial',
    400,
    normal,
    'local',
    'Arial',
    $base_url,
    (
      ascent-override: 87.85%,
      descent-override: 23.92%,
      line-gap-override: 0%,
      size-adjust: 102.45%,
    )
  );
  @include font-face(
    'GT-Walsheim-Pro-Regular-Fallback-Arial',
    400,
    normal,
    'local',
    'Arial',
    $base_url,
    (
      ascent-override: 88.73%,
      descent-override: 24.16%,
      line-gap-override: 0%,
      size-adjust: 101.43%,
    )
  );
}
