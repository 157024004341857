@import './tenantSpecific.scss';
@import '@nextgen-web-framework/all/amway-design-system.scss';
@import '@commerce-payment-webcomponents/ui/commerce-payment-webcomponents.css';
@import '@commerce-checkout-webcomponents/ui/commerce-checkout-webcomponents.css';
@import '@commerce-product-webcomponents/ui/commerce-product-webcomponents.css';
@import '@commerce-search-webcomponents/ui/commerce-search-webcomponents.css';
@import '@commerce-search-webcomponents/ui-composite/commerce-search-webcomponents-ui-composite.css';
@import '@commerce-profile-webcomponents/ui/commerce-profile-webcomponents.css';
@import '@commerce-subscription-webcomponents/ui/commerce-subscription-webcomponents.css';
@import '@commerce-subscription-webcomponents/ui-composite/commerce-subscription-webcomponents-ui-composite.css';
@import '@commerce-order-webcomponents/ui/commerce-order-webcomponents.css';
@import '@commerce-identity-webcomponents/ui/commerce-identity-webcomponents.css';
@import '@commerce-promotion-webcomponents/ui/commerce-promotion-webcomponents.css';
@import '@commerce-sharebar-webcomponents/ui/commerce-sharebar-webcomponents.css';
@import '@commerce-promotion-webcomponents/ui-composite/commerce-promotion-webcomponents-ui-composite.css';
@import '@commerce-product-webcomponents/ui-composite/commerce-product-webcomponents-ui-composite.css';

html,
body,
div#__next {
  height: 100%;
  width: 100vw;
  overflow-x: clip;
  margin: 0;
  padding: 0;
  font-size: 16px;
}
body:has(#full-screen-banner-container-id) {
  overflow: hidden;
}
html {
  @include amway-global-styles;
}

@mixin bottom-style {
  bottom: 66px;
}

.not-found-container {
  display: table;
  width: 100%;
  height: 75vh;
  text-align: center;

  .inner-container {
    display: table-cell;
    vertical-align: middle;
  }

  .inner-container h1 {
    display: inline-block;
    padding-right: 12px;
  }
}

//This is as requested by payment pod and will be removed once component gets fixed from 2p2c side
div[id='pgw-ui-container'] {
  position: absolute;
  z-index: 9999;
}

// MOZILLA FIREFOX FIX FOR 2C2P POPUP not Loading in Firefox
iframe#pgw-ui-container-iframe {
  height: 800px;
}

@include sm-mobile() {
  .cpw-button-container:not(cpw-not-available) {
    @include bottom-style;
  }

  .cpw-button-container.cpw-not-available {
    @include bottom-style;
  }

  .sticky-top-bar {
    display: none;
  }

  .sticky-top-bar-positions {
    position: sticky;
    top: 80px;
    z-index: 3;
    @include flexbox-positional-style($display-flex, unset, center);
  }

  .sticky-bottom-bar {
    width: 100%;
    bottom: 0;
    position: fixed;
    z-index: 1;
  }
}

@include xs-mobile() {
  .sticky-bottom-bar {
    bottom: 66px;
    position: fixed;
    z-index: 3;
  }

  .sticky-top-bar-positions {
    position: sticky;
    top: 60px;
    z-index: 2;
    @include flexbox-positional-style($display-flex, unset, center);
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.error-card-wrapper {
  position: absolute;
  inset: 0;
  margin: auto;
}

iframe#ada-button-frame {
  display: none;
}
// Override Google Map close button to none so that close button is not visible
*.gm-style-iw-chr {
  display: none;
}
// Override default Google Map InfoWindow width
// The default width is set to 304px, which causes unwanted scrolling on mobile view.
// Setting a custom width to prevent this issue.
.gm-style .gm-style-iw-c {
  max-width: 320px !important;
}

// cms components global classes
.font-size-subtitle-1 {
  font-size: $font-size-subtitle-1;
  line-height: $line-height-subtitle-1;
}
.font-size-subtitle-2 {
  font-size: $font-size-subtitle-2;
  line-height: $line-height-subtitle-2;
}
.font-size-body-1 {
  font-size: $font-size-body-1;
  line-height: $line-height-body-1;
}
.font-size-body-2 {
  font-size: $font-size-body-2;
  line-height: $line-height-body-2;
}
